.ReactVirtualized__Table {
    font-size: 14px;
}

.Table-grid {
    margin-bottom: 20px;
    outline: none;
}

.Table-header {
    align-items: flex-start;
}

.ReactVirtualized__Table__headerColumn {
    text-align: center;
}

.ReactVirtualized__Table__headerColumn:focus {
    outline: none;
}

.ReactVirtualized__Table__headerTruncatedText {
    white-space: normal;
}

.ReactVirtualized__Table__row {
    cursor: pointer;
}

.ReactVirtualized__Table__row:focus {
    outline: none;
}

.Table-even-row {
    background-color: #ddd;
}

.Table-odd-row {
    background-color: #ccc;
}

.Table-even-row:hover, .Table-odd-row:hover {
    background-color: #00965E;
    color: white;
}

.App-column-center {
    text-align: center;
}

.App-column-right {
    text-align: right;
}

.Table-client-error {
    color: #FF6600;
}

.Table-server-error {
    color: firebrick;
}
