.Toolbar {
    background-color: #ddd;
    border-radius: 5px;
    margin-bottom: 15px;
    min-height: 48px;
}

.Kpi {
    background-color: #eee;
    border-radius: 10px;
    justify-content: flex-start;
    margin-bottom: 12px;
    margin-right: 12px;
    padding: 3px 5px;
}


.Kpi-value {
    flex-grow: 1;
    font-weight: lighter;
    padding-left: 5px;
    text-align: right;
}

.Kpis-row {
    display: flex;
    flex: 1;
}

.Toolbar {
    align-items: flex-start;
    padding: 12px 12px 0;
}
