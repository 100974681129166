body {
  background-color: #eee;
  margin: 80px 20px 20px 20px !important;
  zoom: 90%;
}

.App-busy * {
  cursor: progress;
}

.App-refresh {
  height: 36px;
  margin-left: 20px;
  margin-top: 10px;
}

.App-refresh:hover {
  background-color: #00a76f;
  border-radius: 5px;
  color: white;
}

.App-refresh-icon {
  margin-left: 5px;
}

.App-export {
  height: 36px;
  margin-left: 20px;
  margin-top: 10px;
}

.App-export:hover {
  background-color: #00a76f;
  border-radius: 5px;
  color: white;
}

.App-export-icon {
  margin-left: 5px;
}

.Chart-title {
  margin-left: 20px;
}

.reset a:visited {
  color: #0000ee;
}

.Column {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.Row {
  display: flex;
}

.dc-chart .pie-slice {
  font-size: 14px;
}

.dc-chart g.row text {
  fill: black;
  font-size: 14px;
}

.dc-chart .axis text {
  font-size: 12px;
}

.dc-legend {
  font-size: 14px;
  max-width: 50px;
}

svg text.pie-label {
  text-shadow: #424242 0.05rem 0.05rem 0.05rem;
}

[data-chart-name='EmptyChart'] {
  width: 240px;
  height: 240px;
}
