input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset;
}

.Dialog-input input {
    padding: 5px;
}

.Dialog-progress-primary {
    background-color: #00965E;
}

.Dialog-progress-secondary {
    background-color: white;
}


