.Details-column-1 {
  color: #660e7a;
  flex: 1 1 0;
  font-weight: bold;
  margin-right: 10px;
  text-align: right;
}

.Details-column-2 {
  color: #008000;
  flex: 1 1 0;
  margin-left: 10px;
  text-align: left;
}

.Details-headers {
  justify-content: space-between;
}

.Json-header {
  flex: 1 1 0;
  font-weight: bold;
  margin: 10px;
  text-align: center;
}

.Json-values {
  justify-content: space-between;
  max-height: 100%;
  min-height: 100%;
  overflow-y: auto;
}

.Json {
  background-color: #ddd;
  font-family: monospace;
  height: 50%;
  padding: 5px;
  width: 48%;
}
